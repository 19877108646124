exports.components = {
  "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx": () => import("./../../../../packages/themes/gatsby-theme-flexiblocks/src/pages/404.jsx" /* webpackChunkName: "component---packages-themes-gatsby-theme-flexiblocks-src-pages-404-jsx" */),
  "component---src-pages-acervo-index-jsx": () => import("./../../../src/pages/acervo/index.jsx" /* webpackChunkName: "component---src-pages-acervo-index-jsx" */),
  "component---src-pages-calcular-roi-index-jsx": () => import("./../../../src/pages/calcular-roi/index.jsx" /* webpackChunkName: "component---src-pages-calcular-roi-index-jsx" */),
  "component---src-pages-departamentos-juridicos-index-jsx": () => import("./../../../src/pages/departamentos-juridicos/index.jsx" /* webpackChunkName: "component---src-pages-departamentos-juridicos-index-jsx" */),
  "component---src-pages-escritorios-index-jsx": () => import("./../../../src/pages/escritorios/index.jsx" /* webpackChunkName: "component---src-pages-escritorios-index-jsx" */),
  "component---src-pages-gestao-conhecimento-index-jsx": () => import("./../../../src/pages/gestao-conhecimento/index.jsx" /* webpackChunkName: "component---src-pages-gestao-conhecimento-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-jurimetria-index-jsx": () => import("./../../../src/pages/jurimetria/index.jsx" /* webpackChunkName: "component---src-pages-jurimetria-index-jsx" */),
  "component---src-pages-jurisprudencia-index-jsx": () => import("./../../../src/pages/jurisprudencia/index.jsx" /* webpackChunkName: "component---src-pages-jurisprudencia-index-jsx" */),
  "component---src-pages-login-index-jsx": () => import("./../../../src/pages/login/index.jsx" /* webpackChunkName: "component---src-pages-login-index-jsx" */),
  "component---src-pages-planos-index-jsx": () => import("./../../../src/pages/planos/index.jsx" /* webpackChunkName: "component---src-pages-planos-index-jsx" */),
  "component---src-pages-politica-privacidade-index-jsx": () => import("./../../../src/pages/politica-privacidade/index.jsx" /* webpackChunkName: "component---src-pages-politica-privacidade-index-jsx" */),
  "component---src-pages-termos-uso-index-jsx": () => import("./../../../src/pages/termos-uso/index.jsx" /* webpackChunkName: "component---src-pages-termos-uso-index-jsx" */)
}

